import React from 'react';
import Animation from './components/animation/container/Container';
import { Helmet } from 'react-helmet';

import * as S from './App.styles';

function App() {
  return (
    <S.App>
      <Helmet>
        <title>Joe Cowton</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <Animation />
    </S.App>
  );
}

export default App;
