import React from 'react';
import * as S from './Column.styles';

export default ({ color, length, top, active }) => {
  const multiplier = active ? 5 : 1;

  const columnStyles = {
    width: `${(top / 10) * (length * multiplier)}%`,
    color: color
  };

  return <S.Column style={columnStyles} color={color}></S.Column>;
};
